@import "../config/config";

$duration: 1.5s;


.page-loader {
  font-size: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-secondary;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease, visibility .25s ease;
  z-index: 101;

  &--show {
    opacity: 1;
    visibility: visible;

    //.loader__letter {
    //  animation-play-state: running;
    //}
  }

  @media (max-width: $bp-991) {
    font-size: calculateBlockFZ(41.7, 315, 991);
  }

  @media (max-width: $bp-425) {
    font-size: calculateBlockFZ(41.7, 215, 425);
  }
}

.loader {
  display: block;
  width: elemEmSize(10, 417);
  height: elemEmSize(10, 182);
}

.loader__letter {
  opacity: 0;
  visibility: hidden;
  //transition: opacity .25s ease, visibility .25s ease;

  &.fadeInOut {
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    //animation-play-state: paused;
  }

  &--1 {
    &.fadeInOut {
      animation-name: fadeInOut1;
    }
  }

  &--2 {
    &.fadeInOut {
      animation-name: fadeInOut2;
    }
  }

  &--3 {
    &.fadeInOut {
      animation-name: fadeInOut3;
    }
  }

  &--show {
    opacity: 1;
    visibility: visible;
  }
}


//@keyframes fadeInOut1 {
//  0% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  16.6666666% {
//    opacity: 1;
//    visibility: visible;
//  }
//  33.3333333% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  49.9999999% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  66.6666666% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  83.3333333% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  100% {
//    opacity: 0;
//    visibility: hidden;
//  }
//}
//
//@keyframes fadeInOut2 {
//  0% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  16.6666666% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  33.3333333% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  49.9999999% {
//    opacity: 1;
//    visibility: visible;
//  }
//  66.6666666% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  83.3333333% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  100% {
//    opacity: 0;
//    visibility: hidden;
//  }
//}
//
//@keyframes fadeInOut3 {
//  0% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  16.6666666% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  33.3333333% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  49.9999999% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  66.6666666% {
//    opacity: 0;
//    visibility: hidden;
//  }
//  83.3333333% {
//    opacity: 1;
//    visibility: visible;
//  }
//  100% {
//    opacity: 0;
//    visibility: hidden;
//  }
//}

@keyframes fadeInOut1 {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  25% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeInOut2 {
  25% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  75% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeInOut3 {
  50% {
    opacity: 0;
    visibility: hidden;
  }
  75% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}