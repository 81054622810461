@import "../config/config";

.page-header {
  position: fixed;
  top: elemEmSize(10, 14);
  left: 0;
  width: 100%;
  background-color: rgba($gray, .4);
  z-index: 99;

  @supports (backdrop-filter: blur(5px)) {
    backdrop-filter: blur(5px);

    @media (max-width: $bp-575) {
      backdrop-filter: none;
      background-color: transparent;
      top: 0;
    }
  }

  @media (max-width: $bp-575) {
    position: absolute;
  }

  .nav-menu__list {
    @media (max-width: $bp-575) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: percentage(180 / 680);
      opacity: 0;
      visibility: hidden;
      transition: .3s all .3s ease;
      z-index: 7;

      &::before {
        content: '';
        position: absolute;
        top: -35%;
        left: 50%;
        width: 0;
        height: 0;
        border-radius: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(
                        180deg,
                        $gray-secondary 0%,
                        rgba($gray-secondary, .96) 74%,
                        rgba($gray-secondary, .5) 87%,
                        rgba($gray-secondary, .1) 100%);
        transition: all .3s ease;
        z-index: -1;
      }
    }
  }
}

.page-header__container {
  display: flex;

  @media (max-width: $bp-575) {
    max-width: 100%;
  }
}

.nav-menu {
  width: 100%;

  @media (max-width: $bp-575) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nav-menu__list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.show {
    opacity: 1;
    visibility: visible;

    &::before {
      width: 150%;
      height: 150%;
    }

    ~.nav-menu__close {
      opacity: 1;
      visibility: visible;
    }
  }
}

.nav-menu__item {
  position: relative;
  padding: elemEmSize(10, 16) 0;

  & + & {
    margin-left: elemEmSize(10, 125);

    @media (max-width: $bp-1439) {
      margin-left: elemEmSize($bp-fz-1439, 70);
    }

    @media (max-width: $bp-1199) {
      margin-left: elemEmSize($bp-fz-1199, 30);
    }

    @media (max-width: $bp-575) {
      margin-left: 0;
      //margin-top: elemEmSize($bp-fz-575, 58);
      margin-top: 7vh;
    }

    @media (max-width: $bp-425) {
      //margin-top: 38px;
      margin-top: 6.7vh;
    }
  }

  &--desktop {
    @media (max-width: $bp-991) {
      display: none;
    }
    display: flex;
    align-items: center;

    .social-icons {
      display: flex;
      align-items: center;

      margin-left: 20px;

      a {
        margin-left: 20px;
      }
    }
  }

  &.active {
    .nav-menu__link {
      pointer-events: none;

      &:link,
      &:visited {
        color: rgba($light, .8)
      }

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:hover {
    .nav-menu__link {
      &:link,
      &:visited {
        color: rgba($light, .8)
      }

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }

    @media (min-width: 1024px) {
      .nav-menu__icon {
        opacity: .8;
        transform: translateY(-50%) rotate(180deg);
      }

      .dropdown-menu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: $bp-991) {
    padding: elemEmSize($bp-fz-991, 30) 0;
  }

  @media (max-width: $bp-575) {
    padding: 0;
  }
}

.nav-mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media(min-width:480px){
    display:none;
  }

  .social-icons{
    margin-right:10px;

    a{
      margin-left:20px;
    }
  }
}

.nav-menu__link {
  font-size: elemEmSize(10, 22);
  font-weight: 600;
  text-transform: uppercase;
  //line-height: 1;
  text-shadow: 0 4px 4px rgba($black, .25);
  position: relative;
  display: inline-block;
  transition: all .2s ease;
  white-space: nowrap;

  @media (min-width: $bp-1921) {
    text-shadow: 0 elemEmSize(22.0052, 4) elemEmSize(22.0052, 4) rgba($black, .25);
  }

  @media (max-width: $bp-575) {
    font-size: elemEmSize($bp-fz-575, 30);
  }

  @media (max-width: $bp-425) {
    font-size: 22px;
  }

  &:link,
  &:visited {
    color: rgba($light, 1)
  }

  &::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: $red;
    filter: blur(6px);
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
    z-index: -1;
  }
}

.nav-menu__icon {
  position: absolute;
  left: 100%;
  top: calc(50% - 0.125em);
  transform: translateY(-50%);
  width: elemEmSize(10, 11);
  height: elemEmSize(10, 12);
  display: inline-block;
  padding: elemEmSize(10, 6);
  box-sizing: content-box;
  transition: all .2s ease;
  cursor: pointer;

  &.active {
    opacity: .8;
    transform: translateY(-50%) rotate(180deg);

    ~ .dropdown-menu {
      opacity: 1;
      visibility: visible;
    }
  }

  &:hover {

    @media (min-width: 1024px) {
      opacity: .8;
      transform: translateY(-50%) rotate(180deg);

      ~ .dropdown-menu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: $bp-575) {
    top: calc(45% - 0.125em);
    width: elemEmSize($bp-fz-575, 14);
    height: elemEmSize($bp-fz-575, 15);
    padding: elemEmSize($bp-fz-575, 6);
  }

  @media (max-width: $bp-425) {
    width: 11px;
    height: 12px;
    padding: 6px;
  }
}

.dropdown {
  padding-right: elemEmSize(22, 4);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: elemEmSize(10, 23);
  padding: elemEmSize(10, 12) elemEmSize(10, 5);
  background-color: rgba($gray, .6);
  min-width: elemEmSize(10, 315);
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease;
  z-index: 99;

  @supports (backdrop-filter: blur(5px)) {
    backdrop-filter: blur(5px);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: elemEmSize(10, 23);
    z-index: -1;

    @media (max-width: $bp-991) {
      height: elemEmSize($bp-fz-991, 5);
    }
  }

  @media (max-width: $bp-991) {
    margin-top: elemEmSize($bp-fz-991, 5);
  }

  @media (max-width: $bp-575) {
    margin-top: elemEmSize($bp-fz-575, 28);
    padding: elemEmSize($bp-fz-575, 10) elemEmSize($bp-fz-575, 5);
    min-width: elemEmSize($bp-fz-575, 575);
  }

  @media (max-width: $bp-425) {
    //margin-top: 28px;
    margin-top: 5vh;
    padding: 10px 5px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.dropdown-menu__item {
  text-align: center;

  & + & {
    margin-top: elemEmSize(10, 10);

    @media (max-width: $bp-575) {
      margin-top: elemEmSize($bp-fz-575, 40);
    }

    @media (max-width: $bp-425) {
      //margin-top: 25px;
      margin-top: 4.4vh;
    }
  }

  @media (max-width: 1024px) {
    &.isMobile {
      display: block;
    }
  }

  &.active {

    .dropdown-menu__link {
      border-bottom-color: $light;
    }
  }
}

.dropdown-menu__link {
  font-size: elemEmSize(10, 20);
  border-bottom: 1px solid transparent;
  text-transform: uppercase;
  transition: border-bottom-color .2s ease;

  &:link,
  &:visited {
    color: $light;
  }

  &:hover {
    border-bottom-color: $light;
  }

  @media (min-width: $bp-1921) {
    border-width: elemEmSize(20, 1);
  }

  @media (max-width: $bp-575) {
    font-size: elemEmSize($bp-fz-575, 28);
  }

  @media (max-width: $bp-425) {
    font-size: 20px;
  }
}

.nav-btn-open,
.nav-btn-close {
  border: none;
  outline: none;
  background-color: transparent;
}

.nav-btn-close {
  position: absolute;
  top: 12px;
  right: 22px;
  opacity: 1;
  visibility: visible;
  transition: .3s all .3s ease;
  z-index: 9;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}

.nav-btn-close__icon {
  display: block;
  width: 32px;
  height: 32px;
  fill: $light
}


.nav-btn-open {
  display: none;
  margin-top: 26px;
  padding: 10px;

  &.hide {
    .path--1,
    .path--3 {
      transform: translateX(100%);
    }

    .path--2,
    .path--4 {
      transform: translateX(-100%);
    }
  }

  @media (max-width: $bp-575) {
    display: block;
  }
}

.nav-btn-open__icon {
  display: block;
  width: 44px;
  height: 32px;

  .path {
    transition: all .3s ease;
  }
}
