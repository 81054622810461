@import "../../config/config";

.projects {
  margin-bottom: elemEmSize(10, 100);

  @media (max-width: $bp-575) {
    margin-bottom: 80px;
  }
}

.projects__title {
  margin-bottom: elemEmSize(140, 30);

  @media (max-width: $bp-425) {
    margin-bottom: 15px;
  }
}

.projects__slider {
  position: relative;

  @media (max-width: $bp-425) {
    max-width: percentage(262 / 290);
  }

  .swiper-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: elemEmSize(10, 30);

    @media (max-width: $bp-425) {
      margin-top: 20px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    top: 0;
    margin-top: 0;
    outline: none;
    width: elemEmSize(10, 27);
    height: elemEmSize(10, 12);
    background-size: elemEmSize(10, 27) elemEmSize(10, 12);

    @media (max-width: $bp-575) {
      width: 27px;
      height: 12px;
      background-size: 27px 12px;
    }
  }


  .swiper-button-prev {
    left: auto;
    background-image: url("data:image/svg+xml,%3Csvg width='27' height='12' viewBox='0 0 27 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6L10 11.7735L10 0.226499L0 6ZM26.5 5L9 5L9 7L26.5 7L26.5 5Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .swiper-button-next {
    right: auto;
    margin-left: elemEmSize(10, 30);
    background-image: url("data:image/svg+xml,%3Csvg width='27' height='13' viewBox='0 0 27 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.5 6.5L16.5 0.726497V12.2735L26.5 6.5ZM0 7.5H17.5V5.5H0V7.5Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}

.project-slide {
  position: relative;

  &:hover {
    .project-slide__desc {
      background-color: rgba($gray, .8);
    }
  }
}

.project-slide__desc {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: elemEmSize(10, 6) elemEmSize(10, 10) elemEmSize(10, 12);
  background-color: rgba($gray, .5);
  transition: background-color .2s ease;
  z-index: 3;

  @media (max-width: $bp-1199) {
    padding: elemEmSize($bp-fz-1199, 6) elemEmSize($bp-fz-1199, 10) elemEmSize($bp-fz-1199, 12);
  }

  @media (max-width: $bp-425) {
    padding: 6px 10px 12px;
  }
}

.project-slide__location,
.project-slide__text {
  font-size: elemEmSize(10, 16);

  @media (max-width: $bp-1199) {
    font-size: elemEmSize($bp-fz-1199, 16);
  }

  @media (max-width: $bp-575) {
    font-size: 16px;
  }
}

.project-slide__location {
  padding-left: elemEmSize(16, 21);
  margin-bottom: elemEmSize(16, 10);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: elemEmSize(16, 11);
    height: elemEmSize(16, 15);
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='15' viewBox='0 0 11 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.38989 1.53871C8.35102 0.546506 6.96967 0 5.50056 0C4.03116 0 2.6501 0.546506 1.61122 1.53871C-0.311363 3.37464 -0.550287 6.82899 1.09379 8.92187L5.50056 15L9.90073 8.93036C11.5514 6.82899 11.3125 3.37464 9.38989 1.53871ZM5.55132 7.12238C4.44447 7.12238 3.54355 6.26194 3.54355 5.20481C3.54355 4.14769 4.44447 3.28725 5.55132 3.28725C6.65818 3.28725 7.55909 4.14769 7.55909 5.20481C7.55909 6.26194 6.65818 7.12238 5.55132 7.12238Z' fill='white'/%3E%3C/svg%3E%0A");
    background-size: elemEmSize(16, 11) elemEmSize(16, 15);
  }

  @media (max-width: $bp-425) {
    margin-bottom: 12px;
  }
}

.project-slide__text {
  font-family: l-gilroy, sans-serif;
}

.project-slide__pic-wrapper {
  min-height: elemEmSize(10, 349);
}

.project-slide__pic {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}