@import "../config/config";


.page-block {
  font-size: 1rem;

  @media (max-width: $bp-1439) {
    font-size: calculateBlockFZ(2.2, 20, 1439);
  }

  @media (max-width: $bp-1199) {
    font-size: calculateBlockFZ(2.2, 18, 1199);
  }

  @media (max-width: $bp-991) {
    font-size: calculateBlockFZ(2.2, 18, 991);
  }

  @media (max-width: $bp-767) {
    font-size: calculateBlockFZ(2.2, 18, 767);
  }

  @media (max-width: $bp-575) {
    font-size: calculateBlockFZ(2.2, 18, 575);
  }
}