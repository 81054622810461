@import "../config/config";

.page-link {
  font-size: elemEmSize(10, 20);
  line-height: 1;
  border-bottom: 1px solid currentColor;
  display: inline-block;
  transition: all .2s ease;

  &:link,
  &:visited {
    color: $light;
  }

  &:hover {
    border-bottom-color: transparent;
  }

  @media (min-width: $bp-1921) {
    border-bottom-width: elemEmSize(20.0052, 1)
  }

  @media (max-width: $bp-575) {
    font-size: 18px;
  }
}
