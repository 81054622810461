// Colors
$light: #fff;
$black: #000;
$gray: #090909;
$gray-secondary: #2a2a2a;
$light-gray: #67686b;
$red: #da3d3d;
$red-secondary: #d33333;

// breakpoints
$bp-1921: 1921px;
$bp-1439: 1439px;
$bp-1199: 1199px;
$bp-991: 991px;
$bp-767: 767px;
$bp-575: 575px;
$bp-425: 425px;

// breakpoints font-size
$bp-fz-1439: 9.09088;
$bp-fz-1199: 8.18186;
$bp-fz-991: 8.1818;
$bp-fz-767: 8.18182;
$bp-fz-575: 8.18179;

// container width
$container-width-1920: 1680;
$container-width-1439: 1280;
$container-width-1199: 960;
$container-width-991: 840;
$container-width-767: 690;
$container-width-575: 290;