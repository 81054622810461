@import "../config/config";

.questions {
  position: relative;
  margin-bottom: elemEmSize(10, 50);
  z-index: 5;

  @media (max-width: $bp-575) {
    margin-bottom: 80px;
  }
}

.questions__title {
  font-size: elemEmSize(10, 138);
  margin-bottom: elemEmSize(138, 50);

  @media (min-width: $bp-1921) {
    -webkit-text-stroke: elemEmSize(138, 2) $light-gray;
  }

  @media (max-width: $bp-1439) {
    font-size: elemEmSize($bp-fz-1439, 100);
  }

  @media (max-width: $bp-1199) {
    font-size: elemEmSize($bp-fz-1199, 78);
  }

  @media (max-width: $bp-767) {
    font-size: elemEmSize($bp-fz-767, 76);
    text-align: center;
  }

  @media (max-width: $bp-425) {
    font-size: 48px;
    margin-bottom: 25px;
  }
}

.questions__form {
  max-width: percentage(686 / $container-width-1920);
  margin: 0 auto;

  .form__button-wrapper {
    justify-content: center;

    @media (max-width: $bp-575) {
      padding-top: 15px;
    }
  }

  @media (max-width: $bp-1439) {
    max-width: percentage(600 / $container-width-1439);
  }

  @media (max-width: $bp-1199) {
    max-width: percentage(600 / $container-width-1199);
  }

  @media (max-width: $bp-767) {
    max-width: percentage(600 / $container-width-767);
  }

  @media (max-width: $bp-575) {
    max-width: 100%;
  }
}