@import "../config/config";

.form__row {
  width: 100%;
  display: flex;

  & + & {
    margin-top: elemEmSize(10, 30);

    @media (max-width: $bp-575) {
      margin-top: 0;
    }
  }

  @media (max-width: $bp-575) {
    flex-direction: column;
  }

  &--h-center {
    justify-content: center;
  }

  &--v-center {
    align-items: center;
  }

  &--no-wrap {
    flex-direction: row !important;
  }
}


.form__item {
  flex: 1;

  & + & {
    margin-left: elemEmSize(10, 30);

    @media (max-width: $bp-575) {
      margin-left: 0;
    }
  }

  @media (max-width: $bp-575) {
    margin-bottom: 10px;
  }

  &--half {
    flex: .5;
    padding-right: elemEmSize(10, 30);

    @media (max-width: $bp-575) {
      flex: 1;
      padding-right: 0;
    }
  }

  &.hide {
    display: none !important;
  }
}

.form__subblock {
  display: none;

  &.show {
    display: block;
  }
}
