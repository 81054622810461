@import "../config/config";

.page-label {
  font-size: elemEmSize(10, 18);
  text-transform: uppercase;
  margin-bottom: elemEmSize(18, 8);
  display: inline-block;

  @media (max-width: $bp-767) {
    font-size: elemEmSize($bp-fz-767, 18);
  }

  @media (max-width: $bp-575) {
    font-size: 18px;
    margin-bottom: 8px;
  }
}

.page-radio-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .page__radio:checked {

    ~.page-radio-label__checkmark::after {
      opacity: 1;
      visibility: visible;
    }
  }

  & + & {
    margin-left: elemEmSize(10, 255);
  }
}

.page-radio-label__caption {
  font-size: elemEmSize(10, 36);
  color: $light;
  line-height: 1;
  text-shadow: 1px 1px $black,
               -1px 1px $black,
               1px -1px $black,
               -1px -1px $black;

  @media (min-width: $bp-1921) {
    text-shadow: elemEmSize(36, 1) elemEmSize(36, 1) $black,
                 elemEmSize(36, -1) elemEmSize(36, 1) $black,
                 elemEmSize(36, 1) elemEmSize(36, -1) $black,
                 elemEmSize(36, -1) elemEmSize(36, -1) $black;
  }

  @media (max-width: $bp-425) {
    font-size: 24px;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

.page-radio-label__checkmark {
  //flex-shrink: 0;
  width: elemEmSize(10, 35);
  height: elemEmSize(10, 35);
  margin-right: elemEmSize(10, 50);
  margin-top: elemEmSize(10, -4);
  border-radius: 50%;
  background-color: $light;
  border: 1px solid $light-gray;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (min-width: $bp-1921) {
    border-width: elemEmSize(10.0052, 1);
  }

  @media (max-width: $bp-425) {
    width: 35px;
    height: 35px;
    margin-right: 40px;
    margin-top: -6px;
  }

  &::after {
    content: '';
    width: elemEmSize(10, 15);
    height: elemEmSize(10, 15);
    border-radius: 50%;
    background-color: $red-secondary;
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease, visibility .25s ease;

    @media (max-width: $bp-425) {
      width: 15px;
      height: 15px;
    }
  }
}