@import "../config/config";

.tab-header-item {

  &.active {

    .page-button-action {
      background-color: $red;
    }
  }
}

.tab-main-item {
  display: none;

  &.active {
    display: block;
  }
}

.page-button-action {
  font-size: elemEmSize(10, 20);
  font-family: l-gilroy, sans-serif;
  font-weight: 800;
  color: $light;
  line-height: (30 / 20);
  text-shadow: 1px 1px $light-gray,
  -1px 1px $light-gray,
  1px -1px $light-gray,
  -1px -1px $light-gray;
  min-height: 100%;
  padding: elemEmSize(20, 5) elemEmSize(20, 10);
  background-color: #1b1a1a;
  border-radius: 10px;
  border: none;
  outline-color: darken(#1b1a1a, 10%);
  transition: background-color .25s ease;
  cursor: pointer;

  &:hover {
    background-color: darken(#1b1a1a, 12%);
  }

  @media (min-width: $bp-1921) {
    text-shadow: elemEmSize(20, 2) elemEmSize(20, 2) $light-gray,
    elemEmSize(20, 2) elemEmSize(20, 2) $light-gray,
    elemEmSize(20, 2) elemEmSize(20, 2) $light-gray,
    elemEmSize(20, 2) elemEmSize(20, 2) $light-gray;
    border-radius: elemEmSize(20, 10);
  }

  @media (max-width: $bp-1439) {
    font-size: elemEmSize($bp-fz-1439, 18);
  }

  @media (max-width: $bp-991) {
    font-size: elemEmSize($bp-fz-991, 22);
  }

  @media (max-width: $bp-575) {
    font-size: 24px;
    padding: 13px 0 14px;
  }
}