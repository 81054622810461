@import "config";

// Fonts
//@include font-face('l-gilroy', 'gilroy', 'gilroy-light', 300);
@include font-face('l-gilroy', 'gilroy', 'gilroy-regular');
//@include font-face('l-gilroy', 'gilroy', 'gilroy-medium', 500);
//@include font-face('l-gilroy', 'gilroy', 'gilroy-bold', 700);
//@include font-face('l-gilroy', 'gilroy', 'gilroy-bolditalic', 700, italic);
@include font-face('l-gilroy', 'gilroy', 'gilroy-extrabold', 800);
//@include font-face('l-gilroy', 'gilroy', 'gilroy-extrabolditalic', 800, italic);

@include font-face('l-oswald', 'oswald', 'oswald-light', 300);
@include font-face('l-oswald', 'oswald', 'oswald-regular', 400);
@include font-face('l-oswald', 'oswald', 'oswald-bold', 600);

