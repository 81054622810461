@import "../config/config";

.page-footer {
  background-image: url("../../../images/footer-background.jpg");
  padding-top: elemEmSize(10, 32);
  padding-bottom: elemEmSize(10, 20);

  &__social-icons {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -5em;

    @media(max-width:1000px){
      position: initial;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      width: 100%;
      margin-left: 0px;
    }

    a{
      margin: 0 10px;
    }
  }

  &::before {
    background-color: rgba($gray, .5);

    @supports (backdrop-filter: blur(2px)) {
      backdrop-filter: blur(2px);

      @media (max-width: $bp-767) {
        backdrop-filter: none;
      }
    }
  }

  .nav-menu {
    max-width: percentage(812 / $container-width-1920);
    flex-basis: percentage(812 / $container-width-1920);
  }

  .nav-menu__list {
    align-items: flex-start;
  }

  .nav-menu__item {
    padding: 0;
  }

  .nav-menu__item + .nav-menu__item {
    margin-left: elemEmSize(10, 30);
  }

  .nav-menu__link {
    font-size: elemEmSize(10, 18);
  }

  .dropdown {
    padding-right: 0;
    justify-content: center;

    &::before {
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    position: relative;
    top: auto;
    left: auto;
    transform: translateX(0);
    margin-top: elemEmSize(10, 20);
    padding: 0;
    background-color: transparent;
    min-width: auto;
    opacity: 1;
    visibility: visible;

    &::before {
      display: none;
    }

    @supports (backdrop-filter: blur(5px)) {
      backdrop-filter: none;
    }
  }

  .dropdown-menu__link {
    font-size: elemEmSize(10, 16);
    font-weight: 300;

    @media (min-width: $bp-1921) {
      border-width: elemEmSize(16, 1);
    }
  }
}

.page-footer__content {
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 5;
  flex-wrap: wrap;

  @media (max-width: $bp-575) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.page-footer__navigation {
  @media (max-width: $bp-1199) {
    display: none;
  }
}

.page-footer__address {
  max-width: percentage(275 / $container-width-1920);
  flex-basis: percentage(275 / $container-width-1920);

  .page-footer__text {
    text-transform: capitalize;
  }

  @media (max-width: $bp-767) {
    max-width: 50%;
    flex-basis: auto;
  }

  @media (max-width: $bp-425) {
    max-width: 255px;
    margin-bottom: 50px;
  }
}

.page-footer__contacts {
  .page-footer__text {
    text-align: right;

    & + & {
      margin-top: elemEmSize(18, 10);
    }
  }
}

.page-footer__text {
  font-size: elemEmSize(10, 18);
  font-weight: 600;
  line-height: (27 / 18);

  .page-link {
    font-size: elemEmSize(18, 18);
    line-height: 1;
    border-bottom-color: transparent;

    &:hover {
      border-bottom-color: $light;
    }
  }

  @media (max-width: $bp-767) {
    font-size: elemEmSize($bp-fz-767, 18);
  }

  @media (max-width: $bp-575) {
    font-size: 18px;
  }
}


@media (max-width: $bp-425){
  .page-footer.page-block{
    padding-top: elemEmSize(10, 50);
    padding-bottom: elemEmSize(10, 40);
  }
}