@import "../../config/config";

.how-its-made {
  position: relative;
  margin-bottom: elemEmSize(10, 100);

  @media (max-width: $bp-575) {
    margin-bottom: 80px;
  }

  .container {
    position: relative;
    z-index: 3;
  }
}

.how-its-made__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../../../../images/how-its-made-background.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 1;

  @supports (mix-blend-mode: color-burn) {
    opacity: .15;
    mix-blend-mode: color-burn;
  }

  @media (max-width: $bp-425) {
    display: none;
  }
}

.how-its-made__title {
  @media (max-width: $bp-575) {
    margin-bottom: 18px;
  }
}

.how-its-made__main {
  display: flex;
  justify-content: space-between;
  padding: 0 elemEmSize(10, 112);

  @media (max-width: $bp-1439) {
    padding: 0;
  }

  @media (max-width: $bp-575) {
    margin-right: percentage(-15 / 290);
  }
}

.how-its-made__pic {
  width: elemEmSize(10, 502);
  height: elemEmSize(10, 805);

  @media (max-width: $bp-767) {
    width: elemEmSize($bp-fz-767, 250);
    height: elemEmSize($bp-fz-767, 401);
  }

  @media (max-width: $bp-575) {
    width: percentage(111 / 305);
    height: auto;
  }

  .sign-svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

//.how-its-made__pic {
//  display: block;
//  width: 100%;
//  height: 100%;
//  object-fit: contain;
//  object-position: center;
//}

.how-its-made__text-wrapper {
  padding-top: elemEmSize(10, 100);

  @media (max-width: $bp-575) {
    padding-top: elemEmSize($bp-fz-575, 70);
  }
}

.how-its-made__text {
  font-size: elemEmSize(10, 40);
  font-weight: 600;
  line-height: (59 / 40);
  max-width: elemEmSize(40, 452);
  width: 100%;
  position: relative;

  @media (max-width: $bp-767) {
    max-width: elemEmSize(32.7273, 330);
  }

  @media (max-width: $bp-575) {
    font-size: elemEmSize($bp-fz-575, 24);
  }

  @media (max-width: $bp-425) {
    font-size: 18px;
  }

  .decor-line {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: elemEmSize(40, 20);
    height: 1px;
    width: elemEmSize(40, 634);
    background-color: $light;

    @media (min-width: $bp-1921) {
      height: elemEmSize(40, 1);
    }

    @media (max-width: $bp-1439) {
      width: elemEmSize(36.3635, 540);
    }

    @media (max-width: $bp-1199) {
      width: elemEmSize(32.7274, 290);
    }

    @media (max-width: $bp-991) {
      width: elemEmSize(32.7272, 190);
    }

    @media (max-width: $bp-767) {
      width: elemEmSize(32.7273, 150);
    }

    @media (max-width: $bp-575) {
      width: elemEmSize(24, 150);
    }

    @media (max-width: $bp-425) {
      width: calc-vw(425, 140);
      margin-left: 8px;
    }

    @media (max-width: 375px) {
      width: calc-vw(375, 115);
    }

    @media (max-width: 340px) {
      width: calc-vw(340, 82);
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: $light;
      border-radius: 50%;
      width: elemEmSize(40, 15);
      height: elemEmSize(40, 15);

      @media (max-width: $bp-575) {
        width: 8px;
        height: 8px;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &--1 {
    margin-bottom: elemEmSize(40, 150);
    margin-left: elemEmSize(40, 80);

    @media (max-width: $bp-1439) {
      margin-left: elemEmSize(36.3635, 60);
    }

    @media (max-width: $bp-767) {
      max-width: elemEmSize(32.7273, 200);
      margin-bottom: elemEmSize(32.7273, 140);
      margin-left: 0;
    }

    @media (max-width: $bp-425) {
      max-width: 112px;
    }

    .decor-line {
      @media (max-width: $bp-767) {
        width: elemEmSize(32.7273, 375);
      }

      @media (max-width: $bp-575) {
        width: elemEmSize(24, 300);
      }

      @media (max-width: $bp-425) {
        width: calc-vw(425, 214);
      }

      @media (max-width: 375px) {
        width: calc-vw(375, 180);
      }

      @media (max-width: 340px) {
        width: calc-vw(340, 150);
      }
    }
  }

  &--2 {
    @media (max-width: $bp-425) {
      max-width: 134px;
    }
  }

}