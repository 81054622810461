@import "config";
@import "fonts";

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

p {
  padding: 0;
  margin: 0;
}

body {
  min-width: 320px;
}

html {
  box-sizing: border-box;
  font-size: calc(100vw / 1920 * 10);
  font-family: 'l-oswald', sans-serif;
  font-weight: 400;
  color: $light;
  line-height: (123 / 100);
}

html, body {
  height: 100%;
}

*, 
*::before,
*::after {
  box-sizing: inherit;
}

button {
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.wrapper {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $gray-secondary;
  overflow: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
