@import "../config/config";

.swal2-popup {

  &.swal2-modal {
    font-size: 15px;
    width: elemEmSize(15, 600);
    background-size: cover !important;
    padding: elemEmSize(15, 80);
    background-color: #e0e0e0;
    border-radius: 0;
    min-height: elemEmSize(15, 390);

    @media (min-width: 1921px) {
      font-size: 1.5rem;
    }

    @media (max-width: $bp-575) {
      padding: 80px 10px;
    }
  }

  //.swal2-icon {
  //  display: none !important;
  //}

  .swal2-title {
    font-size: elemEmSize(15, 48);
    font-family: l-gilroy, sans-serif;
    color: #ff0000;

    @media (max-width: $bp-425) {
      font-size: 36px;
    }

    &.success {
      color: #29ba38;
    }
  }

  .swal2-content {
    font-size: elemEmSize(15, 30);
    font-family: l-gilroy, sans-serif;
    color: $gray-secondary;
    line-height: (35 / 30);

    @media (max-width: $bp-425) {
      font-size: 24px;
    }
  }

  .swal2-text {
    display: block;
  }

  .swal2-button {
    font-size: elemEmSize(15, 22) !important;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    min-width: elemEmSize(22, 350);
    display: inline-block;
    padding: elemEmSize(22, 17) elemEmSize(22, 10) elemEmSize(22, 15);
    border: 1px solid $light;
    border-radius: 0 !important;
    background-color: #ff0000 !important;
    box-shadow: 0 0 5px 1px $red;
    position: relative;
    transition: all .2s ease;

    @media (min-width: $bp-1921) {
      border-width: elemEmSize(15, 1);
      box-shadow: 0 0 elemEmSize(15, 5) elemEmSize(15, 1) $red;
    }

    @media (max-width: $bp-575) {
      min-width: 290px;
    }

    &:focus {
      box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba($red, .4);
    }
  }

  .swal2-close {
    color: $red;
    //color: $light-gray;

    &:hover {
      color: #ff0000;
      //color: lighten($light-gray, 15%);
    }

    &.success {
      color: #29ba38;

      &:hover {
        color: lighten(#29ba38, 15%);
      }
    }
  }
}
