@import "../config/config";

@import "../partials/page-block";
@import "../partials/sweetalert";
@import "./loader";

@import "../ui/buttons";
@import "../ui/titles";
@import "../ui/links";
@import "../ui/form";
@import "../ui/inputs";
@import "../ui/labels";
@import "../ui/tabs";
@import "../ui/lists";


.isMobile {
  display: none;

  @media (max-width: $bp-575) {
    display: block;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.main {
  position: relative;
  //z-index: 9;
  flex: 1;
  background-image: url("../../../images/content-background.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  @media (max-width: $bp-991) {
    background-image: url("../../../images/content-background-mobile.jpg");
  }

  &--home {

    .hero__container {
      padding-bottom: elemEmSize(10, 200);

      @media (max-width: $bp-425) {
        padding-bottom: 76px;
      }
    }
  }

  &--products {
    background-image: url("../../../images/content-background-products.jpg");
  }

  &--quote {

    .hero {

      &__title {
        text-transform: none;
      }

      .page-button {
        display: none;
      }
    }
  }

  &--signs {

    .hero {

      @media (max-width: $bp-575) {
        margin-bottom: 0;
      }

      .page-button {
        display: none;
      }
    }
  }
}

.container {
  max-width: percentage($container-width-1920 / 1920);
  width: 100%;
  margin: 0 auto;

  @media (max-width: $bp-1439) {
    max-width: percentage($container-width-1439 / 1439);
  }

  @media (max-width: $bp-1199) {
    max-width: percentage($container-width-1199 / 1199);
  }

  @media (max-width: $bp-991) {
    max-width: percentage($container-width-991 / 991);
  }

  @media (max-width: $bp-767) {
    max-width: percentage($container-width-767 / 767);
  }

  @media (max-width: $bp-575) {
    max-width: percentage($container-width-575 / 320);
  }
}

.horizontal-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.pos-relative {
  position: relative;
}
