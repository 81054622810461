@import "../config/config";

.hero {
  background-image: url("../../../images/hero-background.jpg");
  margin-bottom: elemEmSize(10, -60);
  clip-path: url(#hero-clip-shape);

  @media (max-width: $bp-991) {
    margin-bottom: elemEmSize($bp-fz-991, -60);
  }

  @media (max-width: $bp-575) {
    clip-path: url(#hero-clip-shape--mobile);
  }

  .page-button--mobile {
    display: none;

    @media (max-width: $bp-991) {
      display: block;
      margin-top: elemEmSize($bp-fz-991, 65);
    }

    @media (max-width: $bp-425) {
      margin-top: 65px;
    }
  }
}

.hero,
.page-footer {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 5;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &::before {
    background-color: rgba($gray, .4);
    z-index: 2;
  }

  &::after {
    background: repeating-linear-gradient(to right,
            #fff,
            #fff 1px,
            transparent 1px,
            transparent percentage(128 / 1920));
    z-index: 1;

    @media (min-width: $bp-1921) {
      background: repeating-linear-gradient(to right,
              #fff,
              #fff elemEmSize(10.0052, 1),
              transparent elemEmSize(10.0052, 1),
              transparent percentage(128 / 1920));
    }

    @media (max-width: $bp-767) {
      background: repeating-linear-gradient(to right,
              #fff,
              #fff 1px,
              transparent 1px,
              transparent percentage(80 / 320));
    }
  }
}

.hero__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: elemEmSize(10, 164);
  padding-bottom: elemEmSize(10, 100);
  position: relative;
  z-index: 5;

  @media (max-width: $bp-425) {
    padding-top: 102px;
    padding-bottom: 102px;
  }
}

.hero__logo {
  margin-bottom: elemEmSize(10, 30);
  position: relative;

  @media (max-width: $bp-425) {
    margin-bottom: 20px;
  }
}

.hero__title {
  // color:red;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 4px 4px rgba($black, .55);
  -webkit-text-stroke: unset;

  @media (min-width: $bp-1921) {
    text-shadow: 0 elemEmSize(100, 4) elemEmSize(100, 4) rgba($black, .55);
  }

  @media (max-width: $bp-425) {
    font-size: 32px;
  }
}
