@import "../config/config";
@import "../config/base";

.page-input,
.page-textarea {
  font-size: elemEmSize(10, 18);
  font-weight: 300;
  line-height: (27 / 18);
  color: $light-gray;
  width: 100%;
  background-color: $light;
  padding: elemEmSize(18, 11) elemEmSize(18, 20) elemEmSize(18, 8);
  border: 1px solid $light-gray;
  display: block;

  &:focus {
    outline: none;
    box-shadow: 0 0 4px 2px lighten($light-gray, 30%);

    @media (min-width: $bp-1921) {
      box-shadow: 0 0 elemEmSize(18, 4) elemEmSize(18, 2) lighten($light-gray, 30%);
    }
  }

  &::placeholder {
    line-height: (27 / 18);
    color: $light-gray;
  }

  @media (max-width: $bp-767) {
    font-size: elemEmSize($bp-fz-767, 18);
  }

  @media (max-width: $bp-575) {
    font-size: 18px;
  }

  &.validate-error {
    border-color: #ff0000;
  }
}

.page-textarea {
  min-height: elemEmSize(18, 126);
  resize: none;
}

.page__radio {
  @extend .visually-hidden;
}
