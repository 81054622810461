@import "../config/config";

.logo {

  &--small {

    .logo__pic {
      width: elemEmSize(10, 230);
      height: elemEmSize(10, 189);

      @media (max-width: $bp-425) {
        width: 194px;
        height: 159px;
      }
    }
  }
}

.logo__pic {
  display: block;
  width: elemEmSize(10, 398);
  height: elemEmSize(10, 327);

  &--blurred {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    @supports (filter: blur(30px)) {
      display: block;
      filter: blur(30px);
    }
  }

  @media (max-width: $bp-425) {
    width: 194px;
    height: 159px;
  }
}