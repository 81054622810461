@import "../config/config";

.page-title {
  font-size: elemEmSize(10, 100);
  font-family: l-gilroy, sans-serif;
  font-weight: 800;
  line-height: (123 / 100);
  //-webkit-text-stroke: 2px $light-gray;
  text-shadow: 2px 2px $light-gray,
               -2px 2px $light-gray,
               2px -2px $light-gray,
               -2px -2px $light-gray;

  @media (min-width: $bp-1921) {
    //-webkit-text-stroke: elemEmSize(100, 2) $light-gray;
    text-shadow: elemEmSize(100, 2) elemEmSize(100, 2) $light-gray,
                 elemEmSize(100, 2) elemEmSize(100, 2) $light-gray,
                 elemEmSize(100, 2) elemEmSize(100, 2) $light-gray,
                 elemEmSize(100, 2) elemEmSize(100, 2) $light-gray;
  }

  @media (max-width: $bp-767) {
    font-size: elemEmSize($bp-fz-767, 64);
    text-align: center;
  }

  @media (max-width: $bp-425) {
    font-size: 34px;
  }

  &--big {
    font-size: elemEmSize(10, 140);

    @media (min-width: $bp-1921) {
      //-webkit-text-stroke: elemEmSize(140, 2) $light-gray;
      text-shadow: elemEmSize(140, 2) elemEmSize(140, 2) $light-gray,
                   elemEmSize(140, 2) elemEmSize(140, 2) $light-gray,
                   elemEmSize(140, 2) elemEmSize(140, 2) $light-gray,
                   elemEmSize(140, 2) elemEmSize(140, 2) $light-gray;
    }

    @media (max-width: $bp-767) {
      font-size: elemEmSize($bp-fz-767, 76);
    }

    @media (max-width: $bp-425) {
      font-size: 48px;
    }
  }

  &--small {
    font-size: elemEmSize(10, 48);

    @media (min-width: $bp-1921) {
      //-webkit-text-stroke: elemEmSize(140, 2) $light-gray;
      text-shadow: elemEmSize(48, 2) elemEmSize(48, 2) $light-gray,
                   elemEmSize(48, 2) elemEmSize(48, 2) $light-gray,
                   elemEmSize(48, 2) elemEmSize(48, 2) $light-gray,
                   elemEmSize(48, 2) elemEmSize(48, 2) $light-gray;
    }

    @media (max-width: $bp-767) {
      font-size: elemEmSize($bp-fz-767, 42);
    }

    @media (max-width: $bp-425) {
      font-size: 36px;
    }
  }
}

.page-title-wrapper {
  position: relative;
  display: flex;
  justify-content: center;

  .page-title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
}

.page-subtitle {
  @extend .page-title;
  font-size: elemEmSize(10, 72);

  @media (min-width: $bp-1921) {
    text-shadow: elemEmSize(72, 2) elemEmSize(72, 2) $light-gray,
                 elemEmSize(72, 2) elemEmSize(72, 2) $light-gray,
                 elemEmSize(72, 2) elemEmSize(72, 2) $light-gray,
                 elemEmSize(72, 2) elemEmSize(72, 2) $light-gray;
  }

  @media (max-width: $bp-767) {
    font-size: elemEmSize($bp-fz-767, 64);
  }

  @media (max-width: $bp-425) {
    font-size: 48px;
  }
}