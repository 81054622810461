@import "../../config/config";

.home-products {
  padding-top: elemEmSize(10, 110);
  margin-bottom: elemEmSize(10, 100);

  @media (max-width: $bp-425) {
    padding-top: 35px;
    margin-bottom: 50px;
  }
}

.home-product-item {
  display: flex;
  //align-items: center;
  position: relative;

  & + & {
    margin-top: elemEmSize(10, 30);

    @media (max-width: $bp-991) {
      margin-top: elemEmSize($bp-fz-991, 50);
    }

    @media (max-width: $bp-575) {
      margin-top: 50px;
    }
  }

  @media (max-width: $bp-991) {
    flex-direction: column;
  }

  &--flip-over {
    flex-direction: row-reverse;

    @media (max-width: $bp-991) {
      flex-direction: column;
    }

    .home-product-item__main {
      align-items: flex-end;

      @media (max-width: $bp-991) {
        align-items: flex-start;
      }
    }

    .home-product-item__pic-wrapper {
      margin-right: 0;
      margin-left: percentage(112 / $container-width-1920);

      @media (max-width: $bp-991) {
        margin-left: 0;
      }
    }

    .home-product-item__title,
    .home-product-item__desc,
    .home-product-advantages__item {
      text-align: right;

      @media (max-width: $bp-991) {
        text-align: left;
      }
    }

    .home-product-advantages__item {
      justify-content: flex-end;
      padding-left: 0;
      padding-right: elemEmSize(20, 30);

      &::before {
        left: auto;
        right: 0;

        @media (max-width: $bp-991) {
          left: 0;
          right: auto;
        }
      }

      @media (max-width: $bp-991) {
        justify-content: flex-start;
        padding-right: 0;
        padding-left: elemEmSize(16.3636, 24);
      }
    }

    .home-product-item__readmore {
      right: auto;
      left: 0;

      @media (max-width: $bp-991) {
        align-self: flex-end;
      }
    }
  }
}

.home-product-item__pic-wrapper {
  max-width: percentage(712 / $container-width-1920);
  flex-basis: percentage(712 / $container-width-1920);
  margin-right: percentage(112 / $container-width-1920);

  @media (max-width: $bp-991) {
    margin-bottom: elemEmSize($bp-fz-991, 30);
    margin-right: 0;
    flex-basis: auto;
    max-width: 85%;
    width: 100%;
  }

  @media (max-width: $bp-575) {
    max-width: 100%;
  }

  @media (max-width: $bp-425) {
    margin-bottom: 15px;
  }
}

.home-product-item__pic {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home-product-item__main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: elemEmSize(10, 80);
  padding-bottom: elemEmSize(10, 40);

  @media (max-width: $bp-991) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.home-product-item__title {
  margin-bottom: elemEmSize(100, 20);

  &.isMobile {
    @media (max-width: $bp-991) {
      display: block;
    }
  }

  @media (max-width: $bp-991) {
    display: none;
  }

  @media (max-width: $bp-425) {
    margin-bottom: 10px;
  }
}

.home-product-item__desc {
  font-size: elemEmSize(10, 20);
  line-height: (30 / 20);
  margin-bottom: elemEmSize(20, 20);

  @media (max-width: $bp-575) {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.home-product-advantages {
  margin-bottom: elemEmSize(10, 20);

  @media (max-width: $bp-575) {
    margin-bottom: 25px;
  }
}

.home-product-advantages__list {
  display: flex;
  flex-direction: column;
}

.home-product-advantages__item {
  font-size: elemEmSize(10, 20);
  line-height: (30 / 20);
  padding-left: elemEmSize(20, 30);
  display: flex;
  align-items: center;
  position: relative;

  & + & {
    margin-top: elemEmSize(20, 10);

    @media (max-width: $bp-575) {
      margin-top: 10px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: elemEmSize(20, 10);
    height: elemEmSize(20, 10);
    background-color: $red;
  }

  @media (max-width: $bp-991) {
    padding-left: elemEmSize(16.3636, 24);
  }

  @media (max-width: $bp-575) {
    font-size: 18px;
  }
}

.home-product-item__readmore {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: $bp-991) {
    position: relative;
    justify-content: flex-end;
    bottom: auto;
    right: auto;
  }
}

.home-product-item__link {
  text-transform: lowercase;

  &:hover {
    ~ .product-readmore__icon {
      transform: translateX(50%);
    }
  }

  @media (max-width: $bp-575) {
    display: none;

    &.isMobile {
      display: block;
    }
  }
}

.home-product-readmore__icon {
  order: -1;
  display: block;
  width: elemEmSize(10, 27);
  height: elemEmSize(10, 13);
  margin-right: elemEmSize(10, 20);
  transition: all .2s ease;

  @media (max-width: $bp-575) {
    width: 27px;
    height: 13px;
  }
}
