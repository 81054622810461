@import "../config/config";


button.page-button,
label.page-button {
  color: $light;
  cursor: pointer;
}

a.page-button {
  &:link,
  &:visited {
    color: $light;
  }
}

.page-button {
  text-transform: uppercase;
  text-align: center;
  line-height: (13 / 10);
  min-width: elemEmSize(10, 350);
  display: inline-block;
  padding: elemEmSize(10, 17) elemEmSize(10, 10) elemEmSize(10, 15);
  background-color: $light;
  box-shadow: 0 0 5px 1px $red;
  position: relative;
  overflow: hidden;
  transition: all .2s ease;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
  }

  &::before {
    bottom: 0;
    left: 0;
    margin: 1px;
    background-color: $red;
    z-index: 1;

    @media (min-width: $bp-1921) {
      margin: elemEmSize(10.0052, 1);
    }
  }

  &::after {
    width: 150%;
    height: 100%;
    transform: skewX(-45deg) translateX(94%);
    background-color: #2b2b2b;
    transition: transform .25s ease;
    z-index: 3;
    right:-18%;

    @media (max-width: $bp-1199) {
      transform: skewX(-45deg) translateX(92%);
    }

    @media (max-width: $bp-991) {
      display: none;
    }
  }

  &:hover {
    &::after {
      transform: skewX(-45deg) translateX(10%);
    }
  }

  .text {
    font-size: elemEmSize(10, 22);
    display: block;
    position: relative;
    z-index: 5;

    @media (max-width: $bp-425) {
      font-size: 20px;
    }
  }

  @media (min-width: $bp-1921) {
    box-shadow: 0 0 elemEmSize(10.0052, 5) 0 $red;
  }

  @media (max-width: $bp-1199) {
    min-width: elemEmSize($bp-fz-1199, 200);
    padding: elemEmSize($bp-fz-1199, 15) elemEmSize($bp-fz-1199, 8) elemEmSize($bp-fz-1199, 11);
  }

  @media (max-width: $bp-991) {
    min-width: elemEmSize($bp-fz-991, 290);
  }

  @media (max-width: $bp-425) {
    min-width: 290px;
    padding: 17px 10px 15px;
  }

  &--clean {

    &::before {
      transition: background-color .25s ease;
    }

    &::after {
      display: none;
    }

    &:hover {

      &::before {
        background-color: darken($red, 7%);
      }
    }
  }
}