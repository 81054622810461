@import "../config/config";

.page-list {

  li {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: elemEmSize(10, 30);

    @media (max-width: $bp-575) {
      padding-left: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-top: elemEmSize(10, 5);
      width: elemEmSize(10, 10);
      height: elemEmSize(10, 10);
      background-color: $red-secondary;

      @media (max-width: $bp-575) {
        width: 10px;
        height: 10px;
        margin-top: 5px;
      }
    }
  }
}